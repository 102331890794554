var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bgcenter" }),
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("headbox", { attrs: { title: "定价详情", bgColor: "#ffa700" } }),
        _c(
          "div",
          { staticClass: "centerboxs" },
          [
            _c("div", { staticClass: "topbg" }),
            _c("div", { staticClass: "flex topboxcenter" }, [
              _vm._m(0),
              _c("div", { staticClass: "topbox" }, [
                _c("div", [_vm._v(_vm._s(_vm.infodetail))]),
                _c("div", { staticClass: "orderdetails" }, [
                  _vm._v(_vm._s(_vm.detailsmsg))
                ])
              ])
            ]),
            _c("div", { staticClass: "orderbox" }, [
              _c("div", { staticClass: "ordermunber" }, [
                _vm._v("订单号：" + _vm._s(_vm.oid))
              ]),
              _c("div", { staticClass: "flex-between" }, [
                _c("div", [_vm._v("定价类别：")]),
                _c("div", [_vm._v(_vm._s(_vm.productlist.name))])
              ]),
              _c("div", { staticClass: "flex-between" }, [
                _c("div", [_vm._v("实时金价(元/克)：")]),
                _c("div", [
                  _vm._v(_vm._s(_vm._f("toFixedF")(_vm.productlist.price)))
                ])
              ]),
              _c("div", { staticClass: "flex-between" }, [
                _c("div", [_vm._v("卖出克重(克)：")]),
                _c("div", [
                  _vm._v(_vm._s(_vm._f("toFixedF")(_vm.productlist.weight)))
                ])
              ]),
              _c("div", { staticClass: "flex-between" }, [
                _c("div", [_vm._v("预估金额(元)：")]),
                _c("div", [
                  _vm._v(
                    "￥" + _vm._s(_vm._f("toFixedF")(_vm.productlist.total))
                  )
                ])
              ]),
              _c("div", { staticClass: "flex-between" }, [
                _c("div", [_vm._v("定价时间：")]),
                _c("div", [_vm._v(_vm._s(_vm._f("formatDate_")(_vm.time)))])
              ])
            ]),
            _vm.ishowBtn.SUSPENSE
              ? _c(
                  "el-button",
                  { staticClass: "btn_yellow", on: { click: _vm.addorder } },
                  [_vm._v("再来一单")]
                )
              : _vm._e(),
            _vm.ishowBtn.FAIL
              ? _c(
                  "el-button",
                  { staticClass: "btn_yellow", on: { click: _vm.addorder } },
                  [_vm._v("重新下单")]
                )
              : _vm._e(),
            _vm.ishowBtn.DONE
              ? _c(
                  "el-button",
                  { staticClass: "btn_yellow", on: { click: _vm.addorder } },
                  [_vm._v("再来一单")]
                )
              : _vm._e(),
            _vm.ishowBtn.UNDELIVERED
              ? _c(
                  "div",
                  { staticClass: "flex footerbox" },
                  [
                    _c(
                      "el-button",
                      { staticClass: "addone", on: { click: _vm.addorder } },
                      [_vm._v("再下一单")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "gosend",
                        on: { click: _vm.gosendproduct }
                      },
                      [_vm._v("去发货")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-circle-check" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }