<template>
  <div>
    <div class="bgcenter"></div>
    <div class="center">
      <headbox title="定价详情" bgColor="#ffa700"></headbox>
      <div class="centerboxs">
        <!-- 定价详情 -->
        <div class="topbg"></div>
        <div class="flex topboxcenter">
          <div>
            <i class="el-icon-circle-check"></i>
          </div>
          <div class="topbox">
            <div>{{ infodetail }}</div>
            <div class="orderdetails">{{ detailsmsg }}</div>
          </div>
        </div>
        <!-- 订单详情 -->
        <div class="orderbox">
          <div class="ordermunber">订单号：{{ oid }}</div>
          <div class="flex-between">
            <div>定价类别：</div>
            <div>{{ productlist.name }}</div>
          </div>
          <div class="flex-between">
            <div>实时金价(元/克)：</div>
            <div>{{ productlist.price | toFixedF }}</div>
          </div>
          <div class="flex-between">
            <div>卖出克重(克)：</div>
            <div>{{ productlist.weight | toFixedF }}</div>
          </div>
          <div class="flex-between">
            <div>预估金额(元)：</div>
            <div>￥{{ productlist.total | toFixedF }}</div>
          </div>
          <div class="flex-between">
            <div>定价时间：</div>
            <div>{{ time | formatDate_ }}</div>
          </div>
        </div>
        <el-button class="btn_yellow" v-if="ishowBtn.SUSPENSE" @click="addorder"
          >再来一单</el-button
        >
        <el-button class="btn_yellow" v-if="ishowBtn.FAIL" @click="addorder"
          >重新下单</el-button
        >
        <el-button class="btn_yellow" v-if="ishowBtn.DONE" @click="addorder"
          >再来一单</el-button
        >
        <div class="flex footerbox" v-if="ishowBtn.UNDELIVERED">
          <el-button class="addone" @click="addorder">再下一单</el-button>
          <el-button class="gosend" @click="gosendproduct">去发货</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate_ } from "@/utils/filters";
import router from "@/router";
import headbox from "@/components/head.vue";
export default {
  data() {
    return {
      time: "",
      // 订单号
      oid: "",
      infodetail: "",
      detailsmsg: "",
      // 商品详情
      productlist: "",
      ishowBtn: {
        SUSPENSE: false,
        FAIL: false,
        UNDELIVERED: false,
        CANCEL: false,
        DONE: false
      }
    };
  },
  components: {
    headbox
  },
  filters: {
    formatDate_,
    toFixedF(val) {
      return Number(val).toFixed(2);
    }
  },
  methods: {
    // 再去下单
    addorder() {
      this.$router.push({ name: "pricing" });
    },
    // 去发货
    gosendproduct() {
      this.$router.push({ name: "pickUp" });
    }
  },
  mounted() {
    this.productlist = JSON.parse(localStorage.getItem("product"));
    // SUSPENSE:未审核,FAIL:未通过,UNDELIVERED:待送达,CANCEL:已取消,DONE:已完成
    const query_ = this.$route.query;
    this.oid = query_.Oid;
    this.time = query_.time;
    if (query_) {
      if (query_.tyPe == "SUSPENSE") {
        this.infodetail = "待审核";
        this.detailsmsg = "您的定价正在审核中，请耐心等待！";
        this.ishowBtn.SUSPENSE = true;
      } else if (query_.tyPe == "FAIL") {
        this.infodetail = "未通过";
        this.detailsmsg = "您的价格有误。";
        this.ishowBtn.FAIL = true;
      } else if (query_.tyPe == "UNDELIVERED") {
        this.infodetail = "待送达";
        this.detailsmsg = "您的定价已审核通过，请在48小时内发货！";
        this.ishowBtn.UNDELIVERED = true;
      } else if (query_.tyPe == "CANCEL") {
        this.infodetail = "已取消";
        this.detailsmsg = "您的订单已取消";
        this.ishowBtn.CANCEL = true;
      } else if (query_.tyPe == "DONE") {
        this.infodetail = "已完成";
        this.detailsmsg = "您的订单已完成";
        this.ishowBtn.DONE = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped="true">
.center {
  position: relative;
  z-index: 2;
}
.btn_yellow {
  font-weight: bold;
  position: fixed;
  bottom: 30px;
  border-radius: 8px;
  left: 50%;
  height: 88px;
  border: 0;
  margin-left: -345px;
  background: #ffb606;
  font-size: 36px;
  color: #000000;
  width: 690px;
}
.topboxcenter {
  position: absolute;
  top: 20px;
  left: 60px;
  i {
    font-size: 60px;
    margin-top: 10px;
  }
  .topbox {
    div {
      font-weight: bold;
      margin-left: 20px;
      font-size: 40px;
    }
    .orderdetails {
      font-weight: normal;
      font-size: 28px;
    }
  }
}
.bgcenter {
  background: #f5f5f5;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.centerboxs {
  position: relative;
  z-index: 2;
}
.ordermunber {
  font-weight: bold;
  font-size: 28px;
  color: #010000;
  border-bottom: 1px dotted #e6e6e6;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.flex-between div:nth-child(1) {
  color: #cccccc;
  font-size: 28px;
  margin-bottom: 25px;
}
.flex-between div:nth-child(2) {
  font-size: 28px;
  color: #333333;
}
.orderbox {
  width: 690px;
  border-radius: 12px;
  padding: 30px;
  background: #fff;
  position: relative;
  top: -200px;
  margin: 0 auto;
}
.topbg {
  background: linear-gradient(#ffa700, rgba(254, 172, 17, 0.4));
  width: 750px;
  height: 353px;
  // box-shadow: 15px 7px 14px 6px #f7deb2;
  box-shadow: 0px 20px 14px 6px #f7deb2;
}
.redfont {
  color: #fa6054;
}
.footerbox {
  position: fixed;
  bottom: 30px;
  margin: 0 30px;
  .addone {
    border-radius: 8px;
    font-size: 36px;
    border: 1px solid #ffb606;
    color: #ffb606;
    height: 88px;
    width: 240px;
    margin-right: 30px;
  }
  .gosend {
    border-radius: 8px;
    font-size: 36px;
    width: 420px;
    height: 88px;
    border: 1px solid #ffb606;
    background: #ffb606;
    color: #000000;
  }
}
.imgminbox {
  margin-left: 25px;
  // margin: 0 auto;
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
}
.bg_box {
  // background: red;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.personbox {
  z-index: 2;
  position: relative;
}
.righticon {
  width: 20px;
  right: 0;
  top: 30%;
  transform: translate(-50%);
  position: absolute;
}
.abs {
  position: absolute;
  top: 150px;
  left: 30px;
  color: #fff;
  .imgbox {
    width: 100px;
  }
}
.list {
  border-radius: 8px;
  margin-top: 20px;
  background: #fff;
}
.msgbox {
  border-radius: 8px;
  background: #fff;
  position: relative;
  top: -50px;
  padding: 30px 40px;
  width: 690px;
  margin: 0 auto;
  color: #999999;
  font-size: 24px;
  b {
    font-size: 40px;
    font-weight: normal;
  }
  .firstdiv {
    width: 320px;
    border-right: 1px dotted #e6e6e6;
  }
  .seconddiv {
    width: 300px;
    margin-left: 40px;
  }
}
.namebox {
  margin-left: 30px;
  font-size: 36px;
  .phone {
    font-size: 26px !important;
  }
}
.centerbox {
  padding: 0 30px;
  .mintitle {
    color: #343434;
    font-size: 30px;
    padding: 25px 30px;
    border-bottom: 1px solid #e6e6e6;
  }
  .flexs {
    padding: 0 25px;
    display: flex;
    div.flexbox {
      flex: 1;
      font-size: 26px;
      color: #707070;
    }
  }
  div.flexbox div:nth-child(2) {
    text-indent: 5px;
  }
  .flex {
    padding: 0 15px 0 35px;
    div.flexbox {
      flex: 1;
      font-size: 26px;
      color: #707070;
      margin: 35px 0px 60px 0;
    }
  }
}
</style>
